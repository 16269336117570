import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import axios from 'axios';
import { csrfToken } from 'rails-ujs';
import ReCAPTCHA from "react-google-recaptcha";
import NotificationSystem from 'react-notification-system';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#404040' },
  },
  typography: { useNextVariants: true },
});

class HomesIndex extends React.Component {
  constructor(props) {
    super(props);
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();
    this.notice = React.createRef();
  }

  showNotice(message) {
    event.preventDefault();
    const notification = this.notice.current;
    notification.addNotification({
      message: message,
      level: 'error',
      position: 'tc'
    });
  }

  render() {
    var noticeStyle = {
      NotificationItem: {
        DefaultStyle: {
          padding: '25px',
          fontSize: '15px'
        }
      }
    }
    return (
      <div className="homes-index">
        <div className="container">
          <section className="top">
            <div className="title">Stack log</div>
          </section>
          <section className="form">
            <div className="verify-form">
              <EmailForm
                showNotice={message => this.showNotice(message)}
              />
            </div>
          </section>
          <section className="arrow">
            <div className="down-arrow"></div>
          </section>
          <section className="concept">
            <div className="message">
              <div className="title">大切なあの人の記憶を。</div>
              <p>Stacklogはあなたの大切な人の情報を密かに記録するお手伝いをいたします。パーティーで知り合った相手から忘れてはいけない彼女の好みまで。毎日の生活を少し豊かにするための秘密のデータベースがここに。</p>
            </div>
            <div className="picture">
              <img src="/images/stacklog_sample.png"/>
            </div>
          </section>
          <NotificationSystem ref={this.notice} style={noticeStyle} />
        </div>
      </div>
    );
  }
}

class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      recaptcha: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRecaptcha = this.handleRecaptcha.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.id]: event.target.value});
  }

  handleRecaptcha(value) {
    this.setState({recaptcha: value});
  }

  handleSubmit(event) {
    axios.post('/sessions/signin_or_signup', {
      email: this.state.email,
      password: this.state.password,
      recaptcha: this.state.recaptcha
    })
    .then( res => {
      var result = res["data"]["result"]
      if (result == "failed_signin") {
        this.props.showNotice('ログイン認証に失敗しました')
      } else if (result == "set_password") {
        this.props.showNotice('/password_resetからパスワードを設定してください')
      } else {
        location.href = res["data"]["result"]
      }
    })
    .catch( err => {
      if (err.response.data.message) {
        this.props.showNotice(err.response.data.message);
      } else {
        this.props.showNotice('失敗しました');
      }
    })
    event.preventDefault();
  }

  render() {
    return (
      <form className="email-form" onSubmit={this.handleSubmit}>
        <FormControl fullWidth={true}>
          <FormLabel className="form-label">Sign up / Sign in</FormLabel>
          <TextField required id="email" label="Email" type="email" name="email" autoComplete="email" margin="normal" variant="outlined" value={this.state.email} onChange={this.handleChange} />
          <TextField required id="password" label="Password" type="password" name="password" autoComplete="password" margin="normal" variant="outlined" value={this.state.password} onChange={this.handleChange} />
          <ReCAPTCHA className="g-recaptcha" sitekey="6Ldq1KEUAAAAAGe8q1Mf956iC5Xv044YQ8LcypIr" onChange={this.handleRecaptcha} theme="dark" />
          <MuiThemeProvider theme={theme}>
            <Button variant="contained" color="primary" type="submit">
              NEXT
            </Button>
          </MuiThemeProvider>
        </FormControl>
        <div className="link"><a href="/password_reset" className="url">パスワードを忘れた方</a></div>
      </form>
    );
  }
}

export default HomesIndex
