import React from 'react';
import axios from 'axios';
import { csrfToken } from 'rails-ujs';
import NotificationSystem from 'react-notification-system';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';

const CssFormControl = withStyles({
  root: {
    display: 'flex',
    '& label.Mui-focused': {
      color: '#837452',
    },
    '& .MuiInputLabel-root': {
      color: '#C2C3BD'
    },
    '& .MuiInputBase-root': {
      color: '#C2C3BD'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#696969',
      },
      '&:hover fieldset': {
        borderColor: '#ffffff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#696969',
      },
    },
  }
})(FormControl);

class UsersSecurity extends React.Component {
  constructor(props) {
    super(props);
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();
    this.notice = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      session: this.props.session
    }
  }

  showNotice(message, level) {
    event.preventDefault();
    const notification = this.notice.current;
    notification.addNotification({
      message: message,
      level: level,
      position: 'tc'
    });
  }

  handleChange(event) {
    axios.post('/users/security/update_session', {
      session: event.target.value
    })
    .then( res => {
      this.setState( { session: res.data.session} )
      this.showNotice("自動ログアウト時間を設定しました", 'success')
    })
    .catch( err =>
      this.showNotice(err.response.data.message, 'error')
    )
    event.preventDefault();
  }

  render () {
    var noticeStyle = {
      NotificationItem: {
        DefaultStyle: {
          padding: '25px',
          fontSize: '15px'
        }
      }
    }
    return (
      <div className="users-security">
        <h1 className="section-header">Security</h1>
        <div className="security-section">
          <div className="row">
            <div className="session-title">自動ログアウトの設定時間</div>
            <div className="session-content">
              <ul>
                <li className="list">
                  <CssFormControl>
                    <div className="session">
                      <Select className="form" native value={this.state.session} onChange={this.handleChange} input={ <OutlinedInput name="sl-sessionstatus" id="session_status" /> }>
                        <option value={1}>60分</option>
                        <option value={6}>6時間</option>
                        <option value={12}>12時間</option>
                        <option value={24}>24時間</option>
                        <option value={72}>3日</option>
                        <option value={168}>7日</option>
                        <option value={336}>14日</option>
                        <option value="default">1ヶ月</option>
                      </Select>
                    </div>
                  </CssFormControl>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <NotificationSystem ref={this.notice} style={noticeStyle} />
      </div>
    );
  }
}

export default UsersSecurity
