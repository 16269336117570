import React from 'react';
import axios from 'axios';
import { csrfToken } from 'rails-ujs';
import NotificationSystem from 'react-notification-system';
import ResizeImage from 'react-image-resizer';
import Button from '@material-ui/core/Button';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Rating from '@material-ui/lab/Rating';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#404040' },
    secondary: { main: '#ce5353'}
  },
  typography: { useNextVariants: true },
});

const CssTextField = withStyles({
  root: {
    '& .MuiInputLabel-root': {
      color: '#C2C3BD'
    },
    '& .MuiInputBase-root': {
      color: '#C2C3BD'
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#696969',
      },
      '&:hover fieldset': {
        borderColor: '#696969',
      },
    },
  }
})(TextField);

class PersonsShow extends React.Component {
  constructor(props) {
    super(props);
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();
    this.notice = React.createRef();
  }

  showNotice(message) {
    event.preventDefault();
    const notification = this.notice.current;
    notification.addNotification({
      message: message,
      level: 'error',
      position: 'tc'
    });
  }

  render () {
    var noticeStyle = {
      NotificationItem: {
        DefaultStyle: {
          padding: '25px',
          fontSize: '15px'
        }
      }
    }
    return (
      <div className="persons-show">
        <Profile contact={this.props.contact} showNotice={message => this.showNotice(message)}/>
        <NotificationSystem ref={this.notice} style={noticeStyle} />
      </div>
    );
  }
}

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.transelateStatus = this.transelateStatus.bind(this);
  }

  transelateStatus(status) {
    switch (status) {
      case 'start':
        return "新規";
      case 'done':
        return "既存";
      case 'pending':
        return "保留";
      case 'left':
        return "放流";
    }
  }

  renderResizeImages() {
    return (
      this.props.contact.images.map((image, index) => (
        <ResizeImage key={index} src={image.url}  width={300} height={300} />
      ))
    )
  }

  handleDeletePerson(id) {
    if(!confirm('本当に削除しますか？')) { return; }
    axios.post('/persons/delete', {
      data: {
        id: id
      }
    })
    .then( res =>
      location.href = "/persons"
    )
    .catch( err =>
      this.props.showNotice('削除に失敗しました')
    )
  }

  render() {
    const renderResizeImages = this.renderResizeImages();
    const edit_url = '/persons/' + this.props.contact.id + '/edit';
    return (
      <div className="profile">
        <div className="first-box">
          <div className="image">
            {renderResizeImages}
          </div>
          <div className="wrapper1">
            <div className="wrapper2">
              <div className="name">
                {this.props.contact.nickname}
              </div>
              <div className="age">
                ({this.props.contact.age})
              </div>
            </div>
            <Rating className="rating" readOnly value={this.props.contact.rating} />
          </div>
        </div>
        <div className="second-box">
          <div className="row">
            <CssTextField className="form" label="birthday" type="date" id="birthday" name="sl-birthday" variant="outlined" InputLabelProps={{ shrink: true }} value={this.props.contact.birthday} disabled />
          </div>
          <div className="row">
            <CssTextField className="form" label="location" type="text" id="location" name="sl-location" variant="outlined" value={this.props.contact.location} disabled />
          </div>
          <div className="row">
            <CssTextField className="form" label="status" type="text" id="status" name="sl-status" variant="outlined" value={this.transelateStatus(this.props.contact.relationship_status)} disabled />
          </div>
          <div className="row">
            <CssTextField className="form" label="memo" type="text" id="memo" name="sl-memo" variant="outlined" multiline rowsMax="15" value={this.props.contact.memo} disabled />
          </div>
        </div>
        <div className="button-wrap">
          <MuiThemeProvider theme={theme}>
            <Button className="button" variant="contained" color="primary" href={edit_url}>Edit</Button>
          </MuiThemeProvider>
          <MuiThemeProvider theme={theme}>
            <Button className="button" variant="contained" color="secondary" onClick={() => this.handleDeletePerson(this.props.contact.id)}>Delete</Button>
          </MuiThemeProvider>
        </div>
      </div>
    )
  }
}

export default PersonsShow
