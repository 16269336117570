import React from 'react';
import axios from 'axios';
import { csrfToken } from 'rails-ujs';
import NotificationSystem from 'react-notification-system';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import ResizeImage from 'react-image-resizer';
import CircularProgress from '@material-ui/core/CircularProgress';
import Rating from '@material-ui/lab/Rating';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#404040' },
    secondary: { main: '#ce5353'}
  },
  typography: { useNextVariants: true },
});

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#837452',
    },
    '& .MuiInputLabel-root': {
      color: '#C2C3BD'
    },
    '& .MuiInputBase-root': {
      color: '#C2C3BD'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#696969',
      },
      '&:hover fieldset': {
        borderColor: '#ffffff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#837452',
      },
    },
  }
})(TextField);

const CssFormControl = withStyles({
  root: {
    display: 'flex',
    '& label.Mui-focused': {
      color: '#837452',
    },
    '& .MuiInputLabel-root': {
      color: '#C2C3BD'
    },
    '& .MuiInputBase-root': {
      color: '#C2C3BD'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#696969',
      },
      '&:hover fieldset': {
        borderColor: '#ffffff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#696969',
      },
    },
  }
})(FormControl);

class PersonsEdit extends React.Component {
  constructor(props) {
    super(props);
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();
    this.notice = React.createRef();
  }

  showNotice(message) {
    event.preventDefault();
    const notification = this.notice.current;
    notification.addNotification({
      message: message,
      level: 'error',
      position: 'tc'
    });
  }

  render () {
    var noticeStyle = {
      NotificationItem: {
        DefaultStyle: {
          padding: '25px',
          fontSize: '15px'
        }
      }
    }
    return (
      <div className="persons-edit">
        <EditProfile
          contact={this.props.contact}
          showNotice={message => this.showNotice(message)}
        />
        <NotificationSystem ref={this.notice} style={noticeStyle} />
      </div>
    );
  }
}

class EditProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nickname: this.props.contact.nickname,
      birthday: this.props.contact.birthday,
      location: this.props.contact.location,
      relationship_status: this.props.contact.relationship_status,
      memo: this.props.contact.memo,
      images: this.props.contact.images,
      delete_image_ids: [],
      saving: false,
      rating: this.props.contact.rating
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeRating = this.handleChangeRating.bind(this);
  }

  handleSubmit(event) {
    this.setState( { saving: true} );
    const params = new FormData;
    params.append('id', this.props.contact.id);
    params.append("delete_image_ids[]", this.state.delete_image_ids);
    params.append("data[nickname]", this.state.nickname);
    params.append("data[birthday]", this.state.birthday);
    params.append("data[location]", this.state.location);
    params.append("data[relationship_status]", this.state.relationship_status);
    params.append("data[memo]", this.state.memo);
    params.append("data[rating]", this.state.rating);
    this.state.images.forEach(image => params.append("images[]", image.file));

    axios.post('/persons/update', params, {
      headers: { 'content-type': 'multipart/form-data' }
    })
    .then( res =>
      location.href = "/persons/" + this.props.contact.id
    )
    .catch( err => {
      this.setState( { saving: false} );
      if (err.response.data.message) {
        this.props.showNotice(err.response.data.message);
      } else {
        this.props.showNotice('更新に失敗しました');
      };
    })
    event.preventDefault();
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  handleChangeImage(index, event) {
    var file = event.target.files[0]
    var tmp_images = this.state.images.slice();
    const reader = new FileReader();
    reader.onload = ( () => {
      tmp_images[index] = { url: reader.result, id: '', file: file };
      this.setState( {images: tmp_images} );
    })
    reader.readAsDataURL(file)
  }

  handleDeleteImage(index, id) {
    var tmp_images = this.state.images.slice();
    tmp_images.splice(index, 1);
    this.setState({
      images: tmp_images,
      delete_image_ids: this.state.delete_image_ids.concat(id)
    });
  }

  renderImages() {
    return (
      this.state.images.map((image, index) => (
        <div key={index} className="images-wrap">
          <div className="image">
            <ResizeImage src={image.url} width={300} height={300} />
          </div>
          <div className="setting">
            <MuiThemeProvider theme={theme}>
              <Button className="button" variant="contained" color="primary">
                <label htmlFor={index}>
                  ファイル選択
                  <input type="file" id={index} className="input-image" name="file" onChange={(e) => this.handleChangeImage(index, e)}  />
                </label>
              </Button>
              <Button className="button" variant="contained" color="primary" onClick={() => this.handleDeleteImage(index, image.id)}>
                削除
              </Button>
            </MuiThemeProvider>
          </div>
        </div>
      ))
    )
  }

  handleChangeRating(event, value) {
    this.setState({ rating: value });
  }

  render() {
    const renderImages = this.renderImages();
    const url = "/persons/" + this.props.contact.id;
    return (
      <div className="edit-person">
        <form className="person-form" onSubmit={this.handleSubmit.bind(this)}>
          <div className="image-form">
            {renderImages}
          </div>
          <div className="row-wrap">
            <div className="row">
              <div className="rating-label">Rating</div>
	            <Rating className="rating" name="rating" value={this.state.rating} onChange={(event, value) => this.handleChangeRating(event, value)} />
            </div>
            <div className="row">
              <CssTextField required className="form" label="nickname" type="text" id="nickname" name="sl-nickname" variant="outlined" value={this.state.nickname} onChange={this.handleChange} />
            </div>
            <div className="row">
              <CssTextField className="form" label="birthday" type="date" id="birthday" name="sl-birthday" variant="outlined" InputLabelProps={{ shrink: true }} value={this.state.birthday} onChange={this.handleChange} />
            </div>
            <div className="row">
              <CssTextField className="form" label="location" type="text" id="location" name="sl-location" variant="outlined" value={this.state.location} onChange={this.handleChange} />
            </div>
            <CssFormControl>
              <div className="row">
                <Select className="form" native value={this.state.relationship_status} onChange={this.handleChange} input={ <OutlinedInput name="sl-relationshipstatus" id="relationship_status" /> }>
                  <option value={'start'}>新規</option>
                  <option value={'done'}>既存</option>
                  <option value={'pending'}>保留</option>
                  <option value={'left'}>放流</option>
                </Select>
              </div>
            </CssFormControl>
            <div className="row">
              <CssTextField className="form" label="memo" type="text" id="memo" name="sl-memo" variant="outlined" multiline rowsMax="15" value={this.state.memo} onChange={this.handleChange} />
            </div>
          </div>
          <div className="button-wrap">
            <MuiThemeProvider theme={theme}>
              <Button className="button" variant="contained" color="primary" type="submit" disabled={this.state.saving}>
                SAVE
              </Button>
            </MuiThemeProvider>
            {this.state.saving && <CircularProgress size={24} className="button-progress" />}
            <MuiThemeProvider theme={theme}>
              <Button className="button" variant="contained" color="secondary" href={url}>
                CANCEL
              </Button>
            </MuiThemeProvider>
          </div>
        </form>
      </div>
    )
  }
}

export default PersonsEdit
