import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import axios from 'axios';
import { csrfToken } from 'rails-ujs';
import ReCAPTCHA from "react-google-recaptcha";
import NotificationSystem from 'react-notification-system';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#404040' },
  },
  typography: { useNextVariants: true },
});

class HomesPasswordReset extends React.Component {
  constructor(props) {
    super(props);
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();
    this.notice = React.createRef();
    this.state = {
      email: '',
      password: '',
      recaptcha: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRecaptcha = this.handleRecaptcha.bind(this);
  }

  showNotice(message) {
    event.preventDefault();
    const notification = this.notice.current;
    notification.addNotification({
      message: message,
      level: 'error',
      position: 'tc'
    });
  }

  handleChange(event) {
    this.setState({[event.target.id]: event.target.value});
  }

  handleRecaptcha(value) {
    this.setState({recaptcha: value});
  }

  handleSubmit(event) {
    axios.post('/sessions/password_reset', {
      email: this.state.email,
      password: this.state.password,
      token: this.props.token,
      recaptcha: this.state.recaptcha
    })
    .then( res =>
      location.href = res["data"]["url"]
    )
    .catch( err => {
      if (err.response.data.message) {
        this.showNotice(err.response.data.message);
      } else {
        this.showNotice('失敗しました');
      }
    })
    event.preventDefault();
  }

  render() {
    var noticeStyle = {
      NotificationItem: {
        DefaultStyle: {
          padding: '25px',
          fontSize: '15px'
        }
      }
    }
    return (
      <div className="homes-password-reset">
        <div className="container">
          <section className="top">
            <div className="title">Stack log</div>
          </section>
          <section className="form">
            <div className="verify-form">
              <form className="form" onSubmit={this.handleSubmit}>
                <FormControl fullWidth={true}>
                  {this.props.status == 'request' && <FormLabel className="form-label">パスワードをリセットする</FormLabel>}
                  {this.props.status == 'update' && <FormLabel className="form-label">新しいパスワードを入力してください</FormLabel>}
                  {this.props.status == 'request' && <TextField required id="email" label="Email" type="email" name="email" autoComplete="email" margin="normal" variant="outlined" value={this.state.email} onChange={this.handleChange} />}
                  {this.props.status == 'update' && <TextField required id="password" label="Password" type="password" name="password" autoComplete="password" margin="normal" variant="outlined" value={this.state.password} onChange={this.handleChange} />}
                  <ReCAPTCHA className="g-recaptcha" sitekey="6Ldq1KEUAAAAAGe8q1Mf956iC5Xv044YQ8LcypIr" onChange={this.handleRecaptcha} theme="dark" />
                  <MuiThemeProvider theme={theme}>
                    <Button variant="contained" color="primary" type="submit">
                      NEXT
                    </Button>
                  </MuiThemeProvider>
                </FormControl>
              </form>
            </div>
          </section>
          <NotificationSystem ref={this.notice} style={noticeStyle} />
        </div>
      </div>
    );
  }
}

export default HomesPasswordReset
