import React from 'react';
import axios from 'axios';
import { csrfToken } from 'rails-ujs';
import InfiniteScroll from 'react-infinite-scroller';
import NotificationSystem from 'react-notification-system';
import AddIcon from '@material-ui/icons/Add';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Avatar from '@material-ui/core/Avatar';
import Rating from '@material-ui/lab/Rating';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';

const CssFormControl = withStyles({
  root: {
    display: 'flex',
    '& label.Mui-focused': {
      color: '#837452',
    },
    '& .MuiInputLabel-root': {
      color: '#C2C3BD'
    },
    '& .MuiInputBase-root': {
      color: '#C2C3BD'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#696969',
      },
      '&:hover fieldset': {
        borderColor: '#ffffff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#696969',
      },
    },
  }
})(FormControl);

const theme = createMuiTheme({
  palette: {
    primary: { main: '#837452' }
  },
  typography: { useNextVariants: true },
});

class PersonsIndex extends React.Component {
  constructor(props) {
    super(props);
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();
    this.notice = React.createRef();
  }

  showNotice(message) {
    event.preventDefault();
    const notification = this.notice.current;
    notification.addNotification({
      message: message,
      level: 'error',
      position: 'tc'
    });
  }

  renderAddIcon() {
    return (
      <div className="button-wrap">
        <MuiThemeProvider theme={theme}>
          <Fab size="large" color="primary" aria-label="Add" className="add-icon" href="/persons/new">
            <AddIcon />
          </Fab>
        </MuiThemeProvider>
      </div>
    );
  }

  render () {
    var noticeStyle = {
      NotificationItem: {
        DefaultStyle: {
          padding: '25px',
          fontSize: '15px'
        }
      }
    }

    const renderAddIcon = this.renderAddIcon();
    return (
      <div className="persons-index">
        { renderAddIcon }
        <div className="wrap">
          <Lists
            showNotice={message => this.showNotice(message)} subscription={this.props.subscription}
          />
        </div>
        <NotificationSystem ref={this.notice} style={noticeStyle} />
      </div>
    );
  }
}

class Lists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      last_id: null,
      hasMore: true,
      items: [],
      sort: "lists"
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate() {
    if (this.state.lists.length > 0) {
      this.setState({ lists: [] })
    }
  }

  fetchLists() {
    axios.get('/persons/' + this.state.sort, {
      params: {
        last_id: this.state.last_id
      }
    })
    .then( res =>
      {
        if (res['data'].length > 0) {
          this.setState({
            last_id: res['data'].slice(-1)[0]['id'],
            lists: res['data']
          })
        } else {
          this.setState({
            lists: [],
            hasMore: false
          })
          if (this.state.items.length == 0) {
            this.props.showNotice('該当するリストは０件です')
          }
        }
      }
    )
    .catch( err =>
      this.props.showNotice("リストの取得に失敗しました")
    )
  }

  handleChange(event) {
    this.setState({
      sort: event.target.value,
      items: [],
      last_id: null,
      hasMore: true
    })
  }

  renderSort() {
    return (
      <div className="sort-wrap">
        <div className="title">並び替え：</div>
        <CssFormControl>
          <Select className="sort" native value={this.state.sort} onChange={this.handleChange} input={ <OutlinedInput name="sl-sort" id="sort_status" /> }>
            <option value="lists">作成日時</option>
            <option value="sort_status">status</option>
            <option value="sort_rating">rating</option>
          </Select>
        </CssFormControl>
      </div>
    );
  }

  render() {
    this.state.lists.map((list) =>
      this.state.items.push(
        <li id={list.id} className="list" key={list.id}>
          <div className="avatar-wrap">
            <Avatar src={list.images[0].url} className="avatar" />
            <div className="avatar-wrap2">
              <div className="avatar-wrap3">
                <div className="avatar-name">{list.nickname}</div>
                <div className="avatar-age">({list.age})</div>
              </div>
              <Rating className="rating" readOnly value={list.rating} />
            </div>
          </div>
          <a className="link" href={'/persons/' + list.id}></a>
        </li>
      )
    );
    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={this.fetchLists.bind(this)}
        hasMore={this.state.hasMore}
        loader={<div className="loader" key={0}>Loading ...</div>}
        threshold={100}
      >
        {this.props.subscription && this.renderSort()}
        <ul>
          {this.state.items}
        </ul>
      </InfiniteScroll>
    );
  }
}

export default PersonsIndex
