import React from 'react';
import axios from 'axios';
import { csrfToken } from 'rails-ujs';
import NotificationSystem from 'react-notification-system';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#404040' },
    secondary: { main: '#ce5353'}
  }
});

class SubscriptionsIndex extends React.Component {
  constructor(props) {
    super(props);
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();
    this.notice = React.createRef();
    this.stripeForm = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  showNotice(message, level) {
    event.preventDefault();
    const notification = this.notice.current;
    notification.addNotification({
      message: message,
      level: level,
      position: 'tc'
    });
  }

  componentDidMount() {
    this.flash();
    var script = document.createElement("script");
    script.setAttribute("src","https://js.stripe.com/v3/");
    script.setAttribute("class","checkout-button");
    this.stripeForm.current.appendChild(script);
  }

  componentWillUnmount() {
    this.stripeForm.current.removeChild(document.getElementById("checkout-button"));
  }

  flash() {
    if (this.props.success) {
      this.showNotice(this.props.success, 'success');
    } else if (this.props.error) {
      this.showNotice(this.props.error, 'error');
    }
  }

  redirectToCheckout(stripe_pk, session_id) {
    var stripe = Stripe(stripe_pk);
    stripe.redirectToCheckout({
      // Make the id field from the Checkout Session creation API response
      // available to this file, so you can provide it as parameter here
      // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
      sessionId: session_id
    }).then(function (result) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      this.showNotice(result.error.message, 'error');
    });
  }

  handleSubmit(path) {
    axios.post(path, {
    })
    .then( res =>
      this.redirectToCheckout(res['data']['stripe_pk'], res['data']['session_id'])
    )
    .catch( err =>
      this.showNotice(err.response.data.message, 'error')
    )
  }

  handleCancel() {
    if(!confirm('本当に解約しますか？')) { return; }
    axios.post('/subscriptions/cancel', {
    })
    .then( res =>
      location.href = "/subscriptions"
    )
    .catch( err =>
      this.showNotice(err.response.data.message, 'error')
    )
  }

  handleResume() {
    axios.post('/subscriptions/resume', {
    })
    .then( res =>
      location.href = "/subscriptions"
    )
    .catch( err =>
      this.showNotice(err.response.data.message, 'error')
    )
  }

  renderButton() {
    var submitPath = this.props.subscription.active ? '/subscriptions/update' : '/subscriptions/create'
    return (
      <div className="button-wrap">
        {!this.props.subscription.active && <Button onClick={() => this.handleSubmit(submitPath)} className="button" variant="contained" color="primary">カードで支払う</Button>}
        {this.props.subscription.continue && <Button onClick={() => this.handleSubmit(submitPath)} className="button" variant="contained" color="primary">カード情報を変更する</Button>}
        {this.props.subscription.continue && <Button onClick={this.handleCancel.bind(this)} className="button" variant="contained" color="secondary">解約する</Button>}
        {this.props.subscription.cancel && <Button onClick={this.handleResume.bind(this)} className="button" variant="contained" color="primary">再開する</Button>}
      </div>
    )
  }

  render () {
    var noticeStyle = {
      NotificationItem: {
        DefaultStyle: {
          padding: '25px',
          fontSize: '15px'
        }
      }
    }
    var submitPath = this.props.subscription.active ? '/subscriptions/update' : '/subscriptions/create'
    return (
      <div className="subscriptions-index">
        <div className="wrap">
          <div className="title">有料プラン</div>
        </div>
        <div className="container">
          <div className="card">
            <div className="plan">Stacklog Pro</div>
            <div className="price">￥５００円 / 月</div>
            <ul>
              <li>ソート機能</li>
              <li>セキュリティ設定</li>
            </ul>
            <div ref={this.stripeForm} className="stripe-form">
              <MuiThemeProvider theme={theme}>
                {this.renderButton()}
              </MuiThemeProvider>
              {!this.props.subscription.expire && <div className="date">有効期限: {this.props.subscription.expiration_date}</div>}
            </div>
          </div>
        </div>
        <div className="fa-q-contents">
          <div className="fa-q-wrap">
            <h3>よくある質問</h3>
            <ul>
              <li className="question">解約はいつでもできますか？</li>
              <li className="answer">はい。いつでも解約できます。<br/><br/>※有料プランの有効期限当日の解約の場合、タイミングによっては翌月の解約になるケースがございます。予めご承知ください。</li>
              <li className="question">どうやって解約するんですか？</li>
              <li className="answer">解約ボタンを押すだけで解約手続き完了です。</li>
              <li className="question">月の途中から契約したら、料金はどうなりますか？</li>
              <li className="answer">いつ課金を開始しても料金は同じで一ヶ月分の有効期限になります。例) １１月１５日に開始 => １２月１５日まで</li>
              <li className="question">有効期限について教えて下さい</li>
              <li className="answer">有料プランに課金いただくと有効期限が表示されるようになります。この表示がある限り、有料プランをご利用いただくことが可能です。</li>
              <li className="question">再開について教えて下さい</li>
              <li className="answer">有料プランを解約すると再開ボタンが表示されるようになります。このボタンがある限り、有料プランを再開することが可能です。<br/><br/>※有料プランの有効期限当日の再開の場合、タイミングによっては再度カードの登録を行っていただくケースがございます。予めご承知ください。</li>
              <li className="question">お金はどこから引き落とされますか？</li>
              <li className="answer">Stripeを通してお客様のクレジットカードからです。</li>
            </ul>
          </div>
        </div>
        <NotificationSystem ref={this.notice} style={noticeStyle} />
      </div>
    );
  }
}

export default SubscriptionsIndex
