import React from 'react';
import axios from 'axios';
import { csrfToken } from 'rails-ujs';
import NotificationSystem from 'react-notification-system';

class UsersIndex extends React.Component {
  constructor(props) {
    super(props);
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();
    this.notice = React.createRef();
  }

  showNotice(message, level) {
    event.preventDefault();
    const notification = this.notice.current;
    notification.addNotification({
      message: message,
      level: level,
      position: 'tc'
    });
  }

  render () {
    var noticeStyle = {
      NotificationItem: {
        DefaultStyle: {
          padding: '25px',
          fontSize: '15px'
        }
      }
    }
    return (
      <div className="users-index">
        <h1 className="user-header">User</h1>
        <div className="settings-section">
          <header className="section-header">
            <h2 className="section-heading">SETTINGS</h2>
          </header>
          <section className="section-content">
            <ul>
              <li className="list"><a className="link" href="/subscriptions">有料プラン</a></li>
              {this.props.subscription && <li className="list"><a className="link" href="/users/security">セキュリティ</a></li>}
            </ul>
          </section>
        </div>
        <NotificationSystem ref={this.notice} style={noticeStyle} />
      </div>
    );
  }
}

export default UsersIndex
